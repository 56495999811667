export default [
	{
		rel: 'icon',
		type: 'image/png',
		sizes: '16x16',
		href: 'https://asset.ochsnersport.ch/image/upload/w_16/favicon.png',
	},
	{
		rel: 'icon',
		type: 'image/png',
		sizes: '32x32',
		href: 'https://asset.ochsnersport.ch/image/upload/w_32/favicon.png',
	},
	{
		rel: 'icon',
		type: 'image/png',
		sizes: '96x96',
		href: 'https://asset.ochsnersport.ch/image/upload/w_96/favicon.png',
	},
	{
		rel: 'preload',
		href: '/fonts/lineto-alpha/lineto-alpha--700.woff2',
		as: 'font',
		crossorigin: 'anonymous',
		fetchpriority: 'low',
	},
	{
		rel: 'preload',
		href: '/fonts/ochsnersport/ochsnersport-2021-11-16.woff2',
		as: 'font',
		crossorigin: 'anonymous',
		fetchpriority: 'low',
	},
	{
		rel: 'preload',
		href: '/fonts/roboto/roboto--400.woff2',
		as: 'font',
		crossorigin: 'anonymous',
		fetchpriority: 'low',
	},
	{
		rel: 'preload',
		href: '/fonts/roboto/roboto--700.woff2',
		as: 'font',
		crossorigin: 'anonymous',
		fetchpriority: 'low',
	},

	{
		rel: 'preload',
		href: 'https://cdn.consentmanager.net/delivery/js/modules/display_custom.min.js',
		as: 'script',
		fetchpriority: 'low',
	},
];
